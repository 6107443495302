<template>
  <modal-card title="Select a billing cycle">
    <div class="columns is-multiline">
      <div
        v-for="(term, index) in sortedTerms"
        :key="`term-${index}`"
        class="column is-12"
      >
        <term-card
          :term="term"
          :currency="currency"
          :class="{ 'is-selected': $_.isEqual(selectedTerm, term) }"
          @click.native="selectTerm(term)"
        />
      </div>
    </div>
    <button
      slot="footer"
      :disabled="!selectedTerm"
      class="button is-success"
      @click="selectTerm(selectedTerm)"
    >
      Continue
    </button>
  </modal-card>
</template>

<script>
export default {
  name: "SelectTerm",
  components: {
    "term-card": () => import("@shared/cart/_termCard")
  },
  props: {
    currency: {
      type: String,
      default: () => {
        return null;
      }
    },
    terms: {
      type: Array,
      required: true
    },
    selectedProductTerm: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      selectedTerm: null
    };
  },
  computed: {
    sortedTerms() {
      return this.$_.orderBy(this.terms, ["termLength"], ["asc"]);
    }
  },
  created() {
    this.selectedTerm = this.selectedProductTerm || this.sortedTerms[0];
  },
  methods: {
    selectTerm(term) {
      this.selectedTerm = term;
      this.$emit("selected", term);
    }
  }
};
</script>
